import {
  PROPERTY_TYPE_MAIN_PROPERTY,
  PROPERTY_TYPE_CONDO,
  PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT,
  PROPERTY_TYPE_UNKNOWN,
  getPropertyType,
} from "~/helpers/property-helpers.js";

export const getLatestCompanyKeyFigure = function (company) {
  if (!company?.keyFigures) {
    return null;
  }

  const sorted = [...company?.keyFigures].sort((a, b) => a.year - b.year);

  let out = {};

  const latest = sorted[sorted.length - 1];

  Object.keys(latest).forEach((key) => {
    out[key] = { value: latest[key] };
  });

  if (sorted.length > 1) {
    const previous = sorted[sorted.length - 2];
    Object.keys(previous).forEach((key) => {
      const percentageDiff = ((latest[key] - previous[key]) / previous[key]) * 100;
      out[key].development = percentageDiff;
    });
  }

  return out;
};

export const getLatestCompanyEbit = function (company) {
  if (!company?.keyFigures) {
    return null;
  }

  const sorted = [...(company?.keyFigures || [])].sort((a, b) => a.year - b.year);
  let out = {};

  const reverseSorted = sorted.reverse();

  let lastYearWithEbit = reverseSorted.find((item) => item.ebit != null && item.ebit != "" && item.ebit != 0);

  if (lastYearWithEbit) {
    Object.keys(lastYearWithEbit).forEach((key) => {
      out[key] = { value: lastYearWithEbit[key] };
    });

    if (reverseSorted.length > 1) {
      const findIndexOfLastYear = (e) => e == lastYearWithEbit;
      const previous = reverseSorted[reverseSorted.findIndex(findIndexOfLastYear) + 1];
      Object.keys(previous).forEach((key) => {
        const percentageDiff = ((lastYearWithEbit[key] - previous[key]) / previous[key]) * 100;
        out[key].development = percentageDiff;
      });
    }
  } else {
    return getLatestCompanyKeyFigure(company);
  }

  return out;
};

export const getLatestCompanyNetIncome = function (company) {
  if (!company?.keyFigures) {
    return null;
  }

  const sorted = [...(company?.keyFigures || [])].sort((a, b) => a.year - b.year);
  let out = {};

  const reverseSorted = sorted.reverse();

  let lastYearWithNetIncome = reverseSorted.find((item) => item.netIncome != null && item.netIncome != "" && item.netIncome != 0);

  if (lastYearWithNetIncome) {
    Object.keys(lastYearWithNetIncome).forEach((key) => {
      out[key] = { value: lastYearWithNetIncome[key] };
    });

    if (reverseSorted.length > 1) {
      const findIndexOfLastYear = (e) => e == lastYearWithNetIncome;
      const previous = reverseSorted[reverseSorted.findIndex(findIndexOfLastYear) + 1];
      Object.keys(previous).forEach((key) => {
        const percentageDiff = ((lastYearWithNetIncome[key] - previous[key]) / previous[key]) * 100;
        out[key].development = percentageDiff;
      });
    }
  } else {
    return getLatestCompanyKeyFigure(company);
  }

  return out;
};

export const getLatestCompanyBalance = function (company) {
  if (!company?.keyFigures) {
    return null;
  }

  const sorted = [...(company?.keyFigures || [])].sort((a, b) => a.year - b.year);

  let out = {};

  const reverseSorted = sorted.reverse();

  let lastYearWithBalance = reverseSorted.find((item) => item.balance != null && item.balance != "" && item.balance != 0);

  if (lastYearWithBalance) {
    Object.keys(lastYearWithBalance).forEach((key) => {
      out[key] = { value: lastYearWithBalance[key] };
    });

    if (reverseSorted.length > 1) {
      const findIndexOfLastYear = (e) => e == lastYearWithBalance;
      const previous = reverseSorted[reverseSorted.findIndex(findIndexOfLastYear) + 1];
      Object.keys(previous).forEach((key) => {
        const percentageDiff = ((lastYearWithBalance[key] - previous[key]) / previous[key]) * 100;
        out[key].development = percentageDiff;
      });
    }
  } else {
    return getLatestCompanyKeyFigure(company);
  }

  return out;
};

export const getCompanyProperies = function (companies) {
  return companies?.flatMap((company) => company.properties).filter((property) => property);
};

export const getCompanyMainProperties = function (companies) {
  return getCompanyProperies(companies)?.filter((prop) => getPropertyType(prop) == PROPERTY_TYPE_MAIN_PROPERTY) || [];
};

export const getCompanyCondos = function (companies) {
  return getCompanyProperies(companies)?.filter((prop) => getPropertyType(prop) == PROPERTY_TYPE_CONDO) || [];
};

export const getCompanyBofp = function (companies) {
  return getCompanyProperies(companies)?.filter((prop) => getPropertyType(prop) == PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT) || [];
};

export const getCompanyUnknownProperties = function (companies) {
  return getCompanyProperies(companies)?.filter((prop) => getPropertyType(prop) == PROPERTY_TYPE_UNKNOWN) || [];
};
