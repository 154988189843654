
export default {
  inheritAttrs: false,
  props: {
    productionUnits: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
