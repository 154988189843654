
export default {
  inheritAttrs: false,
  props: {
    contactInformation: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
