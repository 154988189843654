
import PersonLink from "~/components/EntityLink/components/PersonLink.vue";

export default {
  components: {
    PersonLink,
  },

  inheritAttrs: false,

  props: {
    owners: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasOwners: function () {
      return this.owners.length > 0;
    },
  },
};
