
export default {
  inheritAttrs: false,

  props: {
    companyInformation: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    ensureTranslations(o) {
      if (typeof o === "boolean") {
        return o ? this.$t("COMPANY_OVERVIEW_BOOLEAN_YES") : this.$t("COMPANY_OVERVIEW_BOOLEAN_NO");
      }

      return o;
    },
  },
};
