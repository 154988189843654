import { render, staticRenderFns } from "./ContactCard.vue?vue&type=template&id=33532d6c"
import script from "./ContactCard.vue?vue&type=script&lang=js"
export * from "./ContactCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Icon/Icon.vue').default,Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default,KeyValuePair: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/KeyValuePair/KeyValuePair.vue').default,CompositionSpacer: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/CompositionSpacer.vue').default,CompositionLayoutSingle: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/CompositionLayoutSingle.vue').default,Composition: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/Composition.vue').default})
