var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Composition',{attrs:{"loading":_vm.isLoading,"loadingColor":"--color-black-50","loadingTransformOrigin":"top center","loadingEnabled":""},scopedSlots:_vm._u([{key:"layouts",fn:function(){return [_c('CompositionLayoutSingle',{attrs:{"omitPaddingTop":""},scopedSlots:_vm._u([{key:"container",fn:function(){return [(!_vm.isLoading)?_c('WidgetLayout',{attrs:{"colorBackground":"--color-black-50","rounded":"","shadow":""},scopedSlots:_vm._u([{key:"widgetRows",fn:function(){return [_c('WidgetLayoutRowCellThirds',{scopedSlots:_vm._u([{key:"widgetStart",fn:function(){return [_c('WidgetKeyValue',{attrs:{"header":_vm.$t('COMPANY_KEY_FIGURES_LABEL_NET_INCOME'),"body":_vm._f("currencyCompact")(_vm.netIncome),"colorForeground":"--color-black-900","colorBackground":"--color-white","iconId":_vm.determineIcon(_vm.netIncomeDevelopment),"colorNamespaceIcon":_vm.determineIconColor(_vm.netIncomeDevelopment, true),"byline":`${_vm.getDevelopmentString(_vm.netIncomeDevelopment)}`},on:{"widget-click-more":function($event){return _vm.navigateTo('explore-company-id-key-figures', {
                      activeTab: '1',
                    })}}})]},proxy:true},{key:"widgetCenter",fn:function(){return [_c('WidgetKeyValue',{attrs:{"header":_vm.$t('COMPANY_KEY_FIGURES_LABEL_EBIT'),"body":_vm._f("currencyCompact")(_vm.ebit),"colorForeground":"--color-black-900","colorBackground":"--color-white","iconId":_vm.determineIcon(_vm.ebitDevelopment),"colorNamespaceIcon":_vm.determineIconColor(_vm.ebitDevelopment, true),"byline":`${_vm.getDevelopmentString(_vm.ebitDevelopment)}`},on:{"widget-click-more":function($event){return _vm.navigateTo('explore-company-id-key-figures', {
                      activeTab: '1',
                    })}}})]},proxy:true},{key:"widgetEnd",fn:function(){return [_c('WidgetKeyValue',{attrs:{"header":_vm.$t('COMPANY_KEY_FIGURES_LABEL_BALANCE'),"body":_vm._f("currencyCompact")(_vm.balance),"colorForeground":"--color-black-900","colorBackground":"--color-white","iconId":_vm.determineIcon(_vm.balanceDevelopment),"colorNamespaceIcon":_vm.determineIconColor(_vm.balanceDevelopment, true),"byline":`${_vm.getDevelopmentString(_vm.balanceDevelopment)}`},on:{"widget-click-more":function($event){return _vm.navigateTo('explore-company-id-key-figures', {
                      activeTab: '1',
                    })}}})]},proxy:true}],null,false,2547231814)}),_vm._v(" "),_c('WidgetLayoutRowSpacer'),_vm._v(" "),_c('WidgetLayoutRowCellDouble',{attrs:{"offsetSplit":"","reverseSplit":""},scopedSlots:_vm._u([{key:"widgetStart",fn:function(){return [_c('WidgetBlank',{attrs:{"colorBackground":"--color-white","omitClickMore":""},scopedSlots:_vm._u([{key:"container",fn:function(){return [_c('CompanyInformationCard',{attrs:{"isLoading":_vm.isLoading,"companyInformation":_vm.companyInformation}})]},proxy:true}],null,false,1970413098)})]},proxy:true},{key:"widgetEnd",fn:function(){return [_c('div',{class:_vm.$style.row},[_c('div',{class:_vm.$style.cell},[_c('WidgetBlank',{attrs:{"colorBackground":"--color-white","omitClickMore":""},scopedSlots:_vm._u([{key:"container",fn:function(){return [_c('ContactCard',{attrs:{"isLoading":_vm.isLoading,"contactInformation":_vm.contactInformation}})]},proxy:true}],null,false,3074696001)})],1)]),_vm._v(" "),_c('div',{class:_vm.$style.row},[_c('div',{class:_vm.$style.cell},[_c('WidgetBlank',{attrs:{"colorBackground":"--color-white","omitClickMore":""},scopedSlots:_vm._u([{key:"container",fn:function(){return [_c('OwnersCard',{attrs:{"isLoading":_vm.isLoading,"owners":_vm.realOwners}})]},proxy:true}],null,false,3080144137)})],1)]),_vm._v(" "),_c('div',{class:_vm.$style.row},[_c('div',{class:_vm.$style.cell},[_c('WidgetBlank',{attrs:{"colorBackground":"--color-white","omitClickMore":""},scopedSlots:_vm._u([{key:"container",fn:function(){return [_c('ProductionUnitsCard',{attrs:{"isLoading":_vm.isLoading,"productionUnits":_vm.productionUnits}})]},proxy:true}],null,false,2516307063)})],1)])]},proxy:true}],null,false,3445257036)})]},proxy:true}],null,false,2635425059)}):_vm._e()]},proxy:true}])})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }